<template>
  <div class="MediaOutletIndex">
    <div class="is-flex m-b-m is-aligned-middle">
      <heading size="4" class="is-expanded is-gapless is-marginless">
        Find media outlet
      </heading>
      <router-link :to="{ name: 'staff.media-outlets.create' }" class="button is-primary">
        Add new Media Outlet
      </router-link>
    </div>
    <div class="m-b-m">
      <el-select
        :value="mediaOutletToUpdate"
        :remote-method="queryForMediaOutlets"
        :loading="isFetchingMediaOutlets"
        name="media_outlet"
        placeholder="Select the media outlet"
        filterable
        remote
        popper-class="mediaOutletModal"
        @change="goToMediaOutletEdit"
      >
        <el-option
          v-for="mediaOutlet in mediaOutlets"
          :key="mediaOutlet.id"
          :label="mediaOutlet.name"
          :value="mediaOutlet.id"
        >
          <p class="is-flex is-aligned-middle">
            <country-flag v-if="mediaOutlet.country" :country="mediaOutlet.country.iso_alpha2" />
            {{ mediaOutlet.name }}
          </p>
        </el-option>
      </el-select>
    </div>
  </div>
</template>

<script>
import _orderBy from 'lodash/orderBy'

export default {
  data () {
    return {
      isFetchingMediaOutlets: false,
      mediaOutletToUpdate: null,
      mediaOutlets: []
    }
  },
  methods: {
    queryForMediaOutlets (query) {
      this.isFetchingMediaOutlets = true

      const payload = {
        params: {
          search: query,
          sort: ['name-length'],
          limit: 25
        }
      }

      return this.$api.get('media-outlets', payload)
        .then(response => {
          this.isFetchingMediaOutlets = false
          this.mediaOutlets = _orderBy(response.data.data, 'name')
        })
        .catch(error => {
          this.isFetchingMediaOutlets = false
          this.$displayRequestError(error, 'Media outlets could not be found.')
        })
    },
    goToMediaOutletEdit (mediaOutlet) {
      this.$router.push({ name: 'staff.media-outlets.edit', params: { mediaOutletId: mediaOutlet } })
    }
  }
}
</script>
